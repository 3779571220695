const isBrowser = typeof window !== "undefined";

/*
const ovo_hex = (len) => {
  var maxlen = 8;
  var min = Math.pow(16, Math.min(len, maxlen) - 1);
  var max = Math.pow(16, Math.min(len, maxlen)) - 1;
  var n = Math.floor(Math.random() * (max - min + 1)) + min;
  var r = n.toString(16);
  while (r.length < len) {
    r = r + this.hex(len - maxlen);
  }
  return r;
};
*/
const ovo_uuid = () => {
  return 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, function (c) {
    var r = Math.random() * 16 | 0, v = c === 'x' ? r : (r & 0x3 | 0x8);
    return v.toString(16);
  });
};

export class Ovo {
  constructor() {
    this.tkn = 'ovo';
    this.ses = 'ovo_ses';
    this.lnk = 'ovo_lnk';
    this.tkn_value = ovo_uuid();
    this.ses_value = ovo_uuid();
    this.bind_value = null;
    this.url = '//ianknowles-api.azurewebsites.net/api/Ovo';
    //this.url = '//localhost:7071/api/Ovo';
    this.plg = [];
    //this.clean();
  }

  params = (url) => {  
    if(!isBrowser) return;
    var qs = url ? url.split('?')[1] : window.location.search.slice(1);
    var obj = {};
    if (qs) {
      qs = qs.split('#')[0];
      var arr = qs.split('&');
      for (var i = 0; i < arr.length; i++) {
        var a = arr[i].split('=');
        var pn = a[0];
        var pv = typeof (a[1]) === 'undefined' ? true : a[1];
        pn = pn.toLowerCase();
        if (typeof pv === 'string') pv = pv.toLowerCase();
        if (pn.match(/\[(\d+)?\]$/)) {
          var key = pn.replace(/\[(\d+)?\]/, '');
          if (!obj[key]) obj[key] = [];
          if (pn.match(/\[\d+\]$/)) {
            var index = /\[(\d+)\]/.exec(pn)[1];
            obj[key][index] = pv;
          } else {
            obj[key].push(pv);
          }
        } else {
          if (!obj[pn]) {
              obj[pn] = pv;
          } else if (obj[pn] && typeof obj[pn] === 'string') {
            obj[pn] = [obj[pn]];
            obj[pn].push(pv);
          } else {
            obj[pn].push(pv);
          }
        }
      }
    }
    return obj;
  }
  request = (args) => {
    if(!isBrowser) return;
    return new Promise(function (resolve, reject) {
      let xhr = new XMLHttpRequest();
      xhr.open(args.method, args.url);
      xhr.setRequestHeader('Accept', 'application/json');
      xhr.onload = function () {
        if (this.status >= 200 && this.status < 300) {
          var data = JSON.parse(this.responseText);
          resolve(data);
        } else {
          var response = {};
          try {
            response = JSON.parse(this.responseText);
          } catch (err) { console.log(err); }
          reject({
            status: this.status,
            statusText: xhr.statusText,
            responseText: this.responseText,
            data: response
          });
        }
      };
      xhr.onerror = function () {
        reject({
          status: this.status,
          statusText: xhr.statusText
        });
      };
      if (args.method === 'POST' || args.method === 'PUT'
        || (args.method === 'DELETE' && args.hasOwnProperty('data'))) {
        xhr.setRequestHeader('Content-Type', 'application/json');
        xhr.send(JSON.stringify(args.data));
      } else {
        xhr.send(this.params());
      }
    }.bind(this));
  }
  setCookie = (name, value, days) => {
    if(!isBrowser) return;
    var expires = "";
    if (days) {
      var date = new Date();
      date.setTime(date.getTime() + (days * 24 * 60 * 60 * 1000));
      expires = "; expires=" + date.toUTCString();
    }
    document.cookie = name + "=" + (value || "") + expires + "; path=/";
  }
  getCookie = (name) => {
    if(!isBrowser) return;
    var nameEQ = name + "=";
    var ca = document.cookie.split(';');
    for (var i = 0; i < ca.length; i++) {
      var c = ca[i];
      while (c.charAt(0) === ' ') c = c.substring(1, c.length);
      if (c.indexOf(nameEQ) === 0) return c.substring(nameEQ.length, c.length);
    }
    return null;
  }
  eraseCookie = (name) => {
    if(!isBrowser) return;
    document.cookie = name + '=; Max-Age=-99999999;';
  }
  hex = (len) => {
    var maxlen = 8;
    var min = Math.pow(16, Math.min(len, maxlen) - 1);
    var max = Math.pow(16, Math.min(len, maxlen)) - 1;
    var n = Math.floor(Math.random() * (max - min + 1)) + min;
    var r = n.toString(16);
    while (r.length < len) {
      r = r + this.hex(len - maxlen);
    }
    return r;
  }
  uuid = () => {
    return 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, function (c) {
      var r = Math.random() * 16 | 0, v = c === 'x' ? r : (r & 0x3 | 0x8);
      return v.toString(16);
    });
  }
  c_uid = () => {
    return this.hex(14);
  }
  g_uid = () => {
    if(!isBrowser) return;
    var t_uid = null;
    var pt_uid = null;
    var f = {};
    var uargs = this.params();
    if (uargs[this.tkn] !== undefined) {
      f.up = uargs[this.tkn];
      pt_uid = f.up;
    }
    var store = window.localStorage;
    try {
      if (store !== undefined) {
        f.ls = store.getItem(this.tkn);
      }
    }
    catch (e) { console.warn(e); }
    f.ck = this.getCookie(this.tkn);

    if (f.ls !== undefined && f.ls !== null) {
      t_uid = f.ls;
    }
    else if (f.ck !== undefined && f.ck !== null) {
      t_uid = f.ck;
    }
    else if (f.up !== undefined && f.up !== null) {
      t_uid = f.up;
    }
    if (t_uid === null) {
      t_uid = this.tkn_value;
    }
    if (f.ck === undefined || f.ck === null) {
      this.setCookie(this.tkn, t_uid, 9999);
    }
    if (f.ls === undefined || f.ls === null && store !== undefined) {
      try {
        store.setItem(this.tkn, t_uid);
      }
      catch (e) { console.warn(e); }
    }

    // check parameter does not equal temp but both have values
    // if so link them
    if (pt_uid && t_uid && pt_uid !== t_uid) {
      this.bind_value = pt_uid;
    }

    return t_uid;
  }
  s_uid = () => {
    if(!isBrowser) return;
    var sstore = window.sessionStorage;
    if (sstore === undefined) {
      return null;
    }
    var s_uid = this.params()[this.ses];
    try {
      if (s_uid === null || s_uid === undefined) {
        s_uid = sstore.getItem(this.ses);
      }
      if (s_uid === null || s_uid === undefined) {
        s_uid = this.ses_value;
        sstore.setItem(this.ses, s_uid);
      }
    }
    catch (e) { console.warn(e); }
    return s_uid;
  }
  init = (_ovo) => {
    this.accs = [];
    this.tkn_value = this.g_uid();
    this.ses_value = this.s_uid();
    for (var i = 0; i < _ovo.length; i++) {
      var o = _ovo[i];
      if (o.hasOwnProperty('acc')) {
        this.accs.push({ acc: o.acc });
      }
    }
    this.clean();
    if (this.bind_value) {
      this.send({
        evt: 'BIND',
        tkn: this.tkn_value,
        bind: this.bind_value
      });
    }
  }
  send = (data) => {
    if(!isBrowser) return;
    var d = document;
    var u = this.url;
    data.loc = d.location.href;
    data.ref = d.referrer;
    data.brw = navigator.userAgent;
    data.tkn = this.tkn_value;
    data.ses = this.ses_value;
    data.acc = 1;
    if (navigator.sendBeacon) {
      navigator.sendBeacon(u, JSON.stringify(data));
    }
    else {
      this.request({
        url: u + 'xhr',
        method: 'POST',
        data: data
      });
    }
  }
  ulink = () => {
    if(!isBrowser) return;
    var links = document.querySelectorAll("a[data-ovo]");
    for (var i = 0; i < links.length; i++) {
      var link = links[i].href;
      var args = this.params(links[i].href);
      if (link.indexOf('?') > 0) {
        link = link.substring(0, link.indexOf('?'));
      }
      if (args[this.tkn] === undefined) {
        args[this.tkn] = this.tkn_value;
      }
      if (args[this.ses] === undefined) {
        args[this.ses] = this.ses_value;
      }
      var c = 0;
      for (var key in args) {
        link += (c === 0) ? '?' : '&';
        c++;
        if (args.hasOwnProperty(key)) {
          link += key + '=' + args[key];
        }
      }
      links[i].href = link;
    }
  }
  uclick = () => {
    if(!isBrowser) return;
    var links = document.querySelectorAll("[data-ovo-click]");
    for (var i = 0; i < links.length; i++) {
      var lnk = links[i];
      lnk.args = {
        evt: 'CLICK', val: {
          id: ''
        }
      };
      var v = links[i].dataset.ovoClick;
      if (v) {
        lnk.args.val.id = v;
      }
      links[i].addEventListener('click', (function (e) {
        this.send(e.currentTarget.args);
      }));
    }
  }
  clean = () => {
    if(!isBrowser) return;
    var loc = window.location.href;
    if (loc.indexOf('?') <= 0) {
      return;
    }
    var ps = this.params();
    delete ps[this.ses];
    delete ps[this.tkn];
    loc = loc.split('?')[0];

    if (ps.length === 0) {
      window.history.replaceState({}, document.title, loc);
    } else {
      var qs = '';
      var c = 0;
      for (var key in ps) {
        if (ps.hasOwnProperty(key)) {
          qs += (c === 0) ? '?' : '&';
          c++;
          qs += key + '=' + ps[key];
        }
      }
      window.history.replaceState({}, document.title, loc + qs);
    }
  }
  exec = (_ovo) => {
    if(!isBrowser) return;
    for (var i = 0; i < this.length; i++) {
      var o = _ovo[i];
      if (o.hasOwnProperty('evt')) {
        this.send(o);
      }
    }
  }
};
/*
this.init(_ovo);
this.ulink();
this.uclick();
this.send({ evt: 'OPEN' });
this.exec(_ovo);
window.addEventListener("unload", function logData() {
  this.send({ evt: 'CLOSE' });
});
*/