import * as React from "react"

import { Ovo } from "../services/ovo";

const navClassA = 'nav-collapse nav-collapsible nav-collapsible-right';
const navClassB = 'nav-collapsible nav-collapsible-right';

class Layout extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      navClass: navClassA,
      ovo: new Ovo()/*,
      isBrowser: typeof window !== "undefined"*/
    };

    this.state.ovo.init([1]);
    this.state.ovo.send({ evt: 'OPEN' });
  }

  onUnload = e => { // the method that will be used for both add and remove event
    e.preventDefault();
    e.returnValue = '';
    this.state.ovo.send({ evt: 'CLOSE' });
  }

  componentDidMount() {
    window.addEventListener("unload", this.onUnload);
  }

  componentWillUnmount() {
    window.removeEventListener("unload", this.onUnload);
  }

  dropdown() {
    var navClass = this.state.navClass === navClassA ? navClassB : navClassA;
    this.setState({navClass});
  }
  
  render() {
    return(
      <div>
        <div className="nav-container">
          <div className="container">
            <nav>
              <div className="logo-container">
                <a href="/" data-ovo-click="nav.home"><img src="/images/IanKnowlesLogo.svg" alt="Ian Knowles Logo" /></a>
              </div>
              <ul className={this.state.navClass}>
                <li><a href="/blackbox" data-ovo-click="nav.blackbox">Blackbox</a></li>
                <li><a href="/summit" data-ovo-click="nav.summit">Summit</a></li>
                <li><a href="/music" data-ovo-click="nav.music">Music</a></li>
                <li><a href="/contact" data-ovo-click="nav.contact">Contact</a></li>
              </ul>
              <span className="nav-burger" onClick={this.dropdown.bind(this)} onKeyUp={this.dropdown.bind(this)} role="button" tabIndex={0}><i>&#9776;</i></span>
            </nav>
          </div>
        </div>
        <div className="body-content">
          {this.props.children}
        </div>
        <footer>
          <p>&copy; 2022 - IanKnowles.com | <a href="/privacy" data-ovo-click>Privacy Policy</a></p>
        </footer>
      </div>
    );
  }  
}
export { Layout }